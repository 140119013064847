import React,{Component} from 'react';
import { HashRouter,Route,Switch} from 'react-router-dom';


// Admin Route here
import Login from './view/admin/login';


const NoMatchPage = () => {
  return (
          <img className="imagewidth" src={require('../src/images/404_error.jpg')}  alt="404 Page Missing"/>
  );
};

class Routes extends Component {
   render() {
    return (
     
         <HashRouter> 
            <div>
            <Switch>
                
                <Route path="/contact" component={Login}/>
                
                
                <Route component={NoMatchPage} />
                </Switch>
            </div>
        </HashRouter> 
    ); 
  }
}

export default Routes;