import React,{Component} from 'react';
import 'font-awesome/css/font-awesome.min.css';
import './css/admin/admin.css';
import './css/admin/sb-admin.css';
import Routes from './routes.js';

class Custom extends Component {
   render() {
    return (
        <Routes /> 
    ); 
  }
}

export default Custom;