import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import { ApiLogin } from '../services/Api.js';
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
        
        };
        
    }


    render() {
        return (
          <div class="form-section">
            <div class="form-content">
              <form>
                <h3>Get A Quote</h3>
                <div class="form-group">
                  <input
                    type="Name"
                    class="form-control"
                    id="Name"
                    placeholder="Name"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="Number"
                    class="form-control"
                    id="Number"
                    placeholder="Mobile"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="Email"
                    class="form-control"
                    id="Email"
                    placeholder="Email"
                  />
                </div>
                <div class="form-group">
                  <textarea
                    name="comment"
                    form="usrform"
                    placeholder="message"
                  ></textarea>
                </div>
                <div class="form-group">
                  <a>Submit</a>
                </div>
              </form>
            </div>
          </div>
        );
    }
}
export default Login;